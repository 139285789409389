import {isEmpty} from 'loadsh'
import API from './Config/API';
import { processUpdateFormService } from './services/ProcessUpdateFormService';
import axios from 'axios'; 
import { restClientService } from './services/RestClientService';
import storeClickCountLabelConstants from './Utils/StoreClickCountlabelConstants';

class StoreClickCountService{ 
  async  getClickedButtonAndLabel(event){
  let clickedButton=null;
  let label=null;
  if(event&& event.target &&event.target.id){
    switch(event.target.id){
    case storeClickCountLabelConstants.Save_Button_Desktop :
      clickedButton=storeClickCountLabelConstants.Process_Udpate_Form_Submit_Desktop;
      label=storeClickCountLabelConstants.Process_Update_Form_Submit;
      break;
    
    case 'saveButtonMobile' :
      clickedButton=storeClickCountLabelConstants.Process_Udpate_Form_Submit_Mobile;
      label=storeClickCountLabelConstants.Process_Update_Form_Submit;
      break;

    case storeClickCountLabelConstants.View_Details_Button :
      clickedButton=storeClickCountLabelConstants.View_Details_Button;
      label=storeClickCountLabelConstants.View_Details_Button
      break;
    
    case storeClickCountLabelConstants.Call_Support_Button :
      clickedButton=storeClickCountLabelConstants.Call_Support_Button;
      label=storeClickCountLabelConstants.Call_Support_Button
      break;

    case storeClickCountLabelConstants.Email_Button :
      clickedButton=storeClickCountLabelConstants.Email_Button;
      label=storeClickCountLabelConstants.Email_Button;
      break;
    
    case storeClickCountLabelConstants.Banner_Clicked :
      clickedButton=storeClickCountLabelConstants.Banner_Clicked ;
      label=storeClickCountLabelConstants.Banner_Clicked ;
      break;
    
    case storeClickCountLabelConstants.Facebook  :
      clickedButton=storeClickCountLabelConstants.Facebook ;
      label=storeClickCountLabelConstants.Facebook ;
      break;

    case storeClickCountLabelConstants.LinkedIn  :
      clickedButton=storeClickCountLabelConstants.LinkedIn ;
      label=storeClickCountLabelConstants.LinkedIn ;
      break;
    case storeClickCountLabelConstants.TikTok  :
      clickedButton=storeClickCountLabelConstants.TikTok ;
      label=storeClickCountLabelConstants.TikTok ;
      break;

    case storeClickCountLabelConstants.Pinterest  :
      clickedButton=storeClickCountLabelConstants.Pinterest ;
      label=storeClickCountLabelConstants.Pinterest ;
      break;  
    
    case storeClickCountLabelConstants.Twitter  :
      clickedButton=storeClickCountLabelConstants.Twitter;
      label=storeClickCountLabelConstants.Twitter;
      break;
    
    case storeClickCountLabelConstants.Youtube :
      clickedButton= storeClickCountLabelConstants.Youtube;
      label= storeClickCountLabelConstants.Youtube;
      break;
    
    case  storeClickCountLabelConstants.Instagram :
      clickedButton=storeClickCountLabelConstants.Instagram;
      label=storeClickCountLabelConstants.Instagram;
      break;
    
    case storeClickCountLabelConstants.Privacy_Policy :
      clickedButton= storeClickCountLabelConstants.Privacy_Policy;
      label=event.target.title;
      break;
    
    case storeClickCountLabelConstants.GET_DIRECTION_DESKTOP :
      clickedButton= storeClickCountLabelConstants.GET_DIRECTION_DESKTOP;
      label=storeClickCountLabelConstants.GET_DIRECTION_DESKTOP;
      break;  

    case storeClickCountLabelConstants.PUDO_SHOP_QUICK_ACTION :
      clickedButton= storeClickCountLabelConstants.PUDO_SHOP_QUICK_ACTION;
      label=storeClickCountLabelConstants.PUDO_SHOP_QUICK_ACTION;
      break; 
    
    case storeClickCountLabelConstants.GET_DIRECTION_MOBILE:
      clickedButton=storeClickCountLabelConstants.GET_DIRECTION_MOBILE;
      label=storeClickCountLabelConstants.GET_DIRECTION_MOBILE;
      break;  
  
    case storeClickCountLabelConstants.CALL_STORE_MOBILE:
      clickedButton=storeClickCountLabelConstants.CALL_STORE_MOBILE;
      label=storeClickCountLabelConstants.CALL_STORE_MOBILE;
      break; 

    case storeClickCountLabelConstants.PUDO_FROM_NEED_HELP:
      clickedButton=storeClickCountLabelConstants.PUDO_FROM_NEED_HELP;
      label=storeClickCountLabelConstants.PUDO_FROM_NEED_HELP;
      break;  

    case storeClickCountLabelConstants.SELECT_BUTTON_PUDO_CARD:
      clickedButton=storeClickCountLabelConstants.SELECT_BUTTON_PUDO_CARD;
      label=storeClickCountLabelConstants.SELECT_BUTTON_PUDO_CARD;
      break;   

    case storeClickCountLabelConstants.GET_DIRECTION_PUDO_CARD:
      clickedButton=storeClickCountLabelConstants.GET_DIRECTION_PUDO_CARD;
      label=storeClickCountLabelConstants.GET_DIRECTION_PUDO_CARD;
      break; 

    case storeClickCountLabelConstants.CONFIRM_STORE:
      clickedButton=storeClickCountLabelConstants.CONFIRM_STORE;
      label=storeClickCountLabelConstants.CONFIRM_STORE;
      break;  

    case storeClickCountLabelConstants.LIST_VIEW_MOBILE:
      clickedButton=storeClickCountLabelConstants.LIST_VIEW_MOBILE;
      label=storeClickCountLabelConstants.LIST_VIEW_MOBILE;
      break;      
      
    case storeClickCountLabelConstants.VIEW_ALL_PUDO:
      clickedButton=storeClickCountLabelConstants.VIEW_ALL_PUDO;
      label=storeClickCountLabelConstants.VIEW_ALL_PUDO;
      break;  
    case storeClickCountLabelConstants.Submit_Feedback:
      clickedButton=storeClickCountLabelConstants.Submit_Feedback;
      label=storeClickCountLabelConstants.Submit_Feedback;
      break;  
    case storeClickCountLabelConstants.HELP_LINE_LINK:
      clickedButton=storeClickCountLabelConstants.HELP_LINE_LINK;
      label=storeClickCountLabelConstants.HELP_LINE_LINK;
      break;
    case storeClickCountLabelConstants.Message_to_Fe :
        clickedButton=storeClickCountLabelConstants.Message_to_Fe;
        label=storeClickCountLabelConstants.Message_to_Fe;
    break;  
      
    default:  
  }}
  if(isEmpty(clickedButton) && event.target && event.target.parentElement && event.target.parentElement.id){
    switch(event.target.parentElement.id){
      case  storeClickCountLabelConstants.View_Details_Button :
        clickedButton=storeClickCountLabelConstants.View_Details_Button;
        label=storeClickCountLabelConstants.View_Details_Button;
        break;
      
      case storeClickCountLabelConstants.Call_Button_Mobile :
        clickedButton=storeClickCountLabelConstants.Call_Button_Mobile;
        label=storeClickCountLabelConstants.Call_Button_Mobile;
        break;  
      
      case storeClickCountLabelConstants.Message_to_Fe :
        clickedButton=storeClickCountLabelConstants.Message_to_Fe;
        label=storeClickCountLabelConstants.Message_to_Fe;
        break;  
      
      case storeClickCountLabelConstants.Send_Message_to_Fe_Button:
        clickedButton=storeClickCountLabelConstants.Send_Message_to_Fe_Button;
        label=storeClickCountLabelConstants.Send_Message_to_Fe_Button;
        break;
      
      case 'helpbutton' :
        clickedButton=storeClickCountLabelConstants.Need_Help_Button;
        label=storeClickCountLabelConstants.Need_Help_Button;
        break;  
      
      case storeClickCountLabelConstants.Call_Support_Button :
        clickedButton=storeClickCountLabelConstants.Call_Support_Button;
        label=storeClickCountLabelConstants.Call_Support_Button;
        break;

      case storeClickCountLabelConstants.Email_Button :
        clickedButton=storeClickCountLabelConstants.Email_Button;
        label=storeClickCountLabelConstants.Email_Button;
        break; 

      case storeClickCountLabelConstants.Carousel :
        clickedButton=storeClickCountLabelConstants.Carousel ;
        label="carousel"+event.target.parentElement.name;
        break;   
      
      case storeClickCountLabelConstants.Process_Update_Form  :
        clickedButton=storeClickCountLabelConstants.Process_Update_Form;
        label=event.target.parentElement.innerText;
        break;
      
      case storeClickCountLabelConstants.Submit_Feedback:
        clickedButton=storeClickCountLabelConstants.Submit_Feedback;
        label=storeClickCountLabelConstants.Submit_Feedback;
        break;

      case storeClickCountLabelConstants.CALL_STORE_DESKTOP:
        clickedButton=storeClickCountLabelConstants.CALL_STORE_DESKTOP;
        label=storeClickCountLabelConstants.CALL_STORE_DESKTOP;
        break;  
    
      case storeClickCountLabelConstants.PUDO_SHOP_QUICK_ACTION:
        clickedButton=storeClickCountLabelConstants.PUDO_SHOP_QUICK_ACTION;
        label=storeClickCountLabelConstants.PUDO_SHOP_QUICK_ACTION;
        break;  

      case storeClickCountLabelConstants.GET_DIRECTION_MOBILE:
        clickedButton=storeClickCountLabelConstants.GET_DIRECTION_MOBILE;
        label=storeClickCountLabelConstants.GET_DIRECTION_MOBILE;
        break;  
    
      case storeClickCountLabelConstants.CALL_STORE_MOBILE:
        clickedButton=storeClickCountLabelConstants.CALL_STORE_MOBILE;
        label=storeClickCountLabelConstants.CALL_STORE_MOBILE;
        break; 
      
      case storeClickCountLabelConstants.PUDO_FROM_NEED_HELP:
        clickedButton=storeClickCountLabelConstants.PUDO_FROM_NEED_HELP;
        label=storeClickCountLabelConstants.PUDO_FROM_NEED_HELP;
        break;   

      case storeClickCountLabelConstants.GET_DIRECTION_PUDO_CARD:
        clickedButton=storeClickCountLabelConstants.GET_DIRECTION_PUDO_CARD;
        label=storeClickCountLabelConstants.GET_DIRECTION_PUDO_CARD;
        break;  

      case storeClickCountLabelConstants.LIST_VIEW_MOBILE:
        clickedButton=storeClickCountLabelConstants.LIST_VIEW_MOBILE;
        label=storeClickCountLabelConstants.LIST_VIEW_MOBILE;
        break; 
       
      case storeClickCountLabelConstants.VIEW_MAP_MOBILE:
        clickedButton=storeClickCountLabelConstants.VIEW_MAP_MOBILE;
        label=storeClickCountLabelConstants.VIEW_MAP_MOBILE;
        break;
      case storeClickCountLabelConstants.RETURN_TO_ORDER_TRACKING:
        clickedButton=storeClickCountLabelConstants.MULTI_SHIPMENT;
        label=storeClickCountLabelConstants.RETURN_TO_ORDER_TRACKING;
        break;
      case storeClickCountLabelConstants.OPEN_SHIPMENT:
        clickedButton=storeClickCountLabelConstants.MULTI_SHIPMENT;
        label=storeClickCountLabelConstants.OPEN_SHIPMENT;
        break;   
      default:
    }
  }
  return {clickedButton,label};
}


async storeClickedButtonCount (label,clickedButton,url,merchantCode,isReturn){
    try{
            
            const deviceType = await processUpdateFormService.getDeviceType();
            try{
              url = url.replace(/\s/g, "+");
              let embedUrl=false;
              if(url.indexOf('&embed=true')!= -1){
                url = url.substring(0, url.indexOf('&embed=true'));
                embedUrl = true;
              } else if((window.location.href).indexOf('&embed=true')!= -1){
                embedUrl = true;
              }

              url = await restClientService.updateUrl(url);
              const hiturl = API.SAVE_CLICK_COUNT;
              const res = axios.get(API.SAVE_CLICK_COUNT,{
                  params: {
                    url : url,
                    label: label.toString(),
                    clickedButton: clickedButton.toString(),
                    deviceType: deviceType,
                    merchantCode: merchantCode,
                    isReturn:isReturn||false
                  }
                });
              }
              catch(err){
                  console.log(err);
              }
            }
        catch(err){  
            console.log('error',err);
      }
    };
     

}
function mapStateToProps(state) {
  return {
    url:state.customerInteractionReducer.url,
  };
} 
export const storeClickCountService = new StoreClickCountService();