const API ={
    TRACK_LOG :"/app/rest/get_track_logs",
    GET_TRIP_ETA:"/app/rest/get_trip_eta",
    GET_TRACKING_DETAILS:"/app/rest/get_tracking_details",
    GET_RETURN_TRACKING_DETAILS:"/app/rest/get_return_tracking_details",
    SAVE_DATA:"/app/rest/save_data",
    SEARCH_ADDRESS:"/app/rest/search_address",
    GET_PAYMENT_ORDER_DETAILS:"/app/rest/order",
    GET_SLOTS:"/app/rest/get_required_slots",
    UPDATE_LOCATION :"/app/rest/update_user_location",
    GET_HOLIDAY:"/app/rest/get_holidays",
    UPDATE_PROCESS_FORM:"/app/rest/update_data",
    UPDATE_PROCESS_FORM_FOR_3PL:"/slot_booking_coordinator/book_slots",
    CANCEL_ORDER:"/app/rest/cancel_order",
    SEND_MESSAGE:"/app/rest/send_message",
    SAVE_FEEDBACK:"/app/rest/save_feedback",
    SAVE_FEEDBACK_TO_SHIPMENT:"/app/rest/save_feedback_to_shipment",
    GET_DYNAMIC_SLOT:"/app/rest/get_dynamic_slots",
    GET_3PL_DYNAMIC_SLOT:"/slot_booking_coordinator/get_available_slots",
    GET_BANNER_LIST:"/app/rest/get_banner_list",
    GET_TRACK_LOG_BY_USER:"/app/rest/v2/get_track_logs",
    POST_TRACK_LOG_BY_USER: "/app/rest/v2/track_logs",
    GET_HOLIDAY_MASTER_BY_CODE:"app/rest/get_holiday_master",
    GET_LAST_MILE_AND_STATUS_DETAIL:"app/rest/get_last_mile_and_status_details",
    GET_PREVIOUS_JOBS_INFO:"app/rest/previous_jobs_info",
    GET_AVAILABLE_DATES_FROM_cOORDINATOR:"/slot_booking_coordinator/get_available_dates",
    FETCH_SAVED_FEEDBACK_INFO:"/app/rest/fetch_saved_feedback_info",
    GET_CURBSIDE_PICKUP_BANNER_LIST:"app/rest/get_curbside_pickup_banner_list",
    GET_CURBSIDE_PICKUP_DETAIL: "app/rest/get_curbside_pickup_detail",
    UPDATE_CURBSIDE_PICKUP_PROCESS_FORM:"/app/rest/curbside_pickup_update_data",
    GET_AVAILABLE_DATES_FROM_cOORDINATOR:"/slot_booking_coordinator/get_available_dates",
    SAVE_CURBSIDE_FEEDBACK:"/app/rest/save_curbside_feedback",
    FETCH_SAVED_CURBSIDE_FEEDBACK_INFO:"/app/rest/fetch_saved_curbside_feedback_info",
    SAVE_CLICK_COUNT:"/app/rest/save_click_counts",
    GET_PROCESS_UPDATE_FORMS:"/app/rest/get_process_update_forms",
    GET_ETA:"app/rest/get_eta",
    GET_CI_DIY_THEME:"app/rest/get_ci_diy_theme_setting",
    GET_SHIPMENT_TRACKING_DETAILS:"app/rest/get_shipment_tracking_details",
    GET_MULTI_SHIPMENT_TRACKING_DETAILS:"/app/rest/get_multi_shipment_tracking_details",
    GET_STATE_FROM_FE_STACK_SHIPMENT:"app/rest/get-state",
    GET_UNIVERSAL_TRACKING_URL:"/app/rest/get_universal_tracking_url",
    GET_UNIVERSAL_TRACKING_CONFIG_CI:"/app/rest/get_universal_tracking_config_ci",
    GET_NEW_CI_DIY_DATA:"/app/rest/new_ci_diy_data",
};

export default API;
