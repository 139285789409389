import { setState } from '../reactApp/Utils/setState';
import {processUpdateFormService } from '../reactApp/services/ProcessUpdateFormService'
import  CONSTANTS  from '../reactApp/Utils/Constants'
import {isEmpty} from 'loadsh'
import { setCurbsidePickupData } from './containers/CurbsidePickupContainer/redux/curbsidePickupActionCreator';
import { storeClickCountService } from './StoreClickCountService';
import { isNewLiveTrackingEnabled } from './containers/LiveTracking/LiveTrackingAction';
import moment from 'moment';

export function getTrackingDetails(url, isReturn,trackingDetails,isMultiShipment){
    return async function (dispatch) {
        try{
            
            let holidayMaster;
            const deviceType = await processUpdateFormService.getDeviceType()        // check device is mobile/tablet  or desktop
            let response =   await processUpdateFormService.getTrackingDetails(url,deviceType, isReturn);
            const processUpdateFormResponse =   await processUpdateFormService.getProcessUpdateForms(url,deviceType,isReturn);
            console.log(" multipart Shipment::",isMultiShipment,url,response)
            if (response) {
                if(response.calledFromDiy && trackingDetails && trackingDetails.customerInteractionThemeSettingsDTO){
                    response.customerInteractionThemeSettingsDTO = trackingDetails.customerInteractionThemeSettingsDTO;
                    if(!isEmpty(trackingDetails.companyLogo)){
                        response.companyLogo = trackingDetails.companyLogo
                    }
                    response.needHelp = trackingDetails?.needHelp
                }
                let favIconUrl = response.customerInteractionThemeSettingsDTO && response.customerInteractionThemeSettingsDTO.favIcon;
                processUpdateFormService.setFavIconUrl(favIconUrl);
                let { processStatusLogDomainList: [{ processStatusLogDto: [{ companyId, processMasterId, referenceNumber } = {}] = [] } = {}] = [] } = response;
                if(response.companyId || response.pmId){
                    window.dataLayer.push({companyId: response.companyId,processMasterId: response.pmId,referenceNumber: response.referenceNumber,merchantCode:response.vCode });
                }
            }
            if(processUpdateFormResponse){
                response = {...processUpdateFormResponse,...response}
            }
            if(isMultiShipment){
                response["orderLevelLayoutScreenDTO"] = trackingDetails.orderLevelLayoutScreenDTO;
            } else if(response.msp){
                const orderLevelResponse =   await processUpdateFormService.getMultiShipmentDetails(url,deviceType, isReturn);;
                if(orderLevelResponse && orderLevelResponse.orderLevelLayoutScreenDTO){
                    response["orderLevelLayoutScreenDTO"] = orderLevelResponse.orderLevelLayoutScreenDTO
                }
            }

            if(isNewLiveTrackingEnabled(response)) { // remove this once it goes live on prod
                console.log("new Livetracking in action");
                window.localStorage.setItem("newLiveTracking", true);
            } else {
                window.localStorage.removeItem("newLiveTracking");
            }
                       
            if(response && response["feedbackUpdated"]){
                if(response.customerInteractionThemeSettingsDTO) {
                    try{
                        response.customerInteractionThemeSettingsDTO = JSON.parse(response.customerInteractionThemeSettingsDTO);
                    } catch(err) {
                        console.log(err);
                        response.customerInteractionThemeSettingsDTO = null;
                    }
                }
                dispatch(setState(CONSTANTS.SET_FEEDBACK,{feedback:true,details:response}))
            }
            else if(response=="notFound"){
                dispatch(setState(CONSTANTS.NOT_FOUND,true))
            } else {
                if(typeof response == 'object'){response.isMobileType=deviceType;}
                let check={},alreadyUpdated = false;
                const nextJobTransactionId = getNextTransactionId(response);
                
                let config3PLDataMap = response.config3PLDataMap || {};
                let processUrl = response.processUrl;
                if(response.updated){
                     alreadyUpdated = true;
                    dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster, nextJobTransactionId, config3PLDataMap,processUrl}))
                } else {
                    if(response.hubId){
                        holidayMaster = await processUpdateFormService.getHolidayMaster(url,response.hubId);
                    }
                    if(response.processInteractionKeysDtos){
                                response.processInteractionKeysDtos.map(attributes=>{
                                    if(attributes.typeId==8 && attributes.value && !isEmpty(attributes.value)){
                                        let value = JSON.parse(attributes.value)
                                        value.map(values=>{
                                            check[values] =true;
                                        })     
                                    }
                                }) 
                            }
                        dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster, nextJobTransactionId, config3PLDataMap,processUrl}))
                   }
            }
            if(response){
                // let parsedProcessUpdateForm = JSON.parse(response.processUpdateForm);
                // processUpdateFormService.getAvailableDatesFromSlotBookingCoordinator(response.processUrl);
            }
            }catch(err){
              dispatch(setState(CONSTANTS.NOT_FOUND,true))
              console.log('error',err);
        }
    };
}

const getNextTransactionId = (trackingDetails) => {
    let nextJobTransactionId = null;
    if (trackingDetails &&
        trackingDetails.liveMonitoringDto &&
        trackingDetails.liveMonitoringDto.userSummaryDTO &&
        trackingDetails.liveMonitoringDto.userSummaryDTO.nextTransactionId) {
        nextJobTransactionId = trackingDetails.liveMonitoringDto.userSummaryDTO.nextTransactionId;
    }
    return nextJobTransactionId;
}


export function updateTrackingDetails(details){
    return async function (dispatch) {
        try{
            dispatch(setState(CONSTANTS.UPDATE_TRACKING_DETAILS,details))
            console.log(details,"trackingDetails")
            }catch(err){
            console.log('err',err);
        }
    };
}

export function saveData(data,url,slotNumber,slotList){
    return async function(dispatch){
        try{
              const res = await processUpdateFormService.saveData(data,url,slotNumber,slotList);
              if(res != 'OK'){
                dispatch(setState(CONSTANTS.NOT_FOUND,true))
              } else {
                dispatch(setState(CONSTANTS.ALREADY_UPDATED,true))
              }
             
        } catch(err){
            console.log("err",err);
        }
    }
}

export function getSlotFromServer(data,url){
    return async function(dispatch){
        try {   
                let res =[];
                let alertMessage = "No Available Slot Found";
                let response = await processUpdateFormService.getSlotFromServer(data,url);
                 if(!isEmpty(response) && response.indexOf("400")==-1){
                    res = response;
                    alertMessage ="*Select an available time slot"
                 }
                dispatch(setState(CONSTANTS.SET_SLOT_LIST,{res,alertMessage}));
        } catch(err){
                console.log("err",err);    
        }
    }
}

export function updateCheckbox(check){
    return async function(dispatch){
        try {
                dispatch(setState(CONSTANTS.SET_CHECKBOX,check));
        } catch(err){
                console.log("err",err);    
        }
    }
}


export function updateLocation(url,lat,lng,message){
    return async function(dispatch){
        try{
              const res = await processUpdateFormService.updateLocation(url,lat,lng,message);
               return res;
             
        } catch(err){
            console.log("err",err);
        }
    }
}


export function setCircleCenter(lat,lng,rad){
    return async function(dispatch){
        try{
            let set = "valid";
            if(lat==null){
                set="notValid"
            }
            dispatch(setState(CONSTANTS.SET_CIRCLE_CENTER,{set,lat,lng,rad}))
        } catch(err){
            console.log("err",err);
        }
    }
}

export function setJobUpdated(jobUpdated){
    return async function(dispatch){
        try{
            dispatch(setState(CONSTANTS.SET_JOB_UPDATE,jobUpdated))
        } catch(err){
            console.log("err",err);
        }
    }
}


export function updateProcessForm(processInteractionKeysDtos,masterCode,ref,url,title,dynamicSlotDate,dynamicSlots,isPayment,productAmount, config3PLDataMap,trackingDetails,form,dynamicSlotList){
    return async function(dispatch){
        try{
            let config3PLForSelectedForm = {};
            if(!isEmpty(config3PLDataMap) && config3PLDataMap[form.title]){
                 config3PLForSelectedForm = config3PLDataMap[form.title]; 
            }
            let res = await processUpdateFormService.updateProcessForm(processInteractionKeysDtos,masterCode,ref,url,title,dynamicSlotDate,dynamicSlots, config3PLForSelectedForm,trackingDetails,form,dynamicSlotList)
            console.log("response while",res);
        } catch(err){
            console.log("err",err);
        }
    }
}

export function saveFeedback(url,feedbackObj,details,isV2, type,isReturn){
    return async function(dispatch){
        try{
            let processUpdateFormJson = JSON.parse(details.processUpdateForm)?JSON.parse(details.processUpdateForm):"";
            let timeStampKey ="";
            if(!isEmpty(processUpdateFormJson)){
                timeStampKey = processUpdateFormJson["processUpdateTimeStamp"]?processUpdateFormJson["processUpdateTimeStamp"]:"";
           
            }
            let timeStampValue = moment().format('YYYY-MM-DD HH:mm:ss');
            let res = await processUpdateFormService.saveFeedback(url,feedbackObj, type,timeStampKey,timeStampValue,isReturn)
            if(res==200){
             if(!isV2){
                 dispatch(fetchFeedbackInfo(url, false, type,isReturn));
             } else {
                if( !isEmpty(feedbackObj.customerInteractionThemeSettingsDTO) && 
                    feedbackObj.customerInteractionThemeSettingsDTO.constructor === String) {
                    feedbackObj = {...feedbackObj, 
                        customerInteractionThemeSettingsDTO: JSON.parse(feedbackObj.customerInteractionThemeSettingsDTO)};
                    }
                    dispatch(setState(CONSTANTS.SET_FEEDBACK,{feedback:true,details:details}))
                }
             }   
           
           return res;
        } catch(err){
            console.log("err",err);
        }
    }
}

export function getDynamicSlots(date,form,config3PLDataMap,trackingDetails,processUrl,coordinatorSlotObject){
    return async function(dispatch){
        try{
            if(coordinatorSlotObject && !coordinatorSlotObject.needToCallAgain && !isEmpty(coordinatorSlotObject.availableSlots)){
                let slots = [];
                    console.log("slots",coordinatorSlotObject.availableSlots);
                    if(coordinatorSlotObject.availableSlots[0] && coordinatorSlotObject.availableSlots[0].date && coordinatorSlotObject.availableSlots[0].date==date){
                        if(!isEmpty(config3PLDataMap) && config3PLDataMap[form.title] && !config3PLDataMap[form.title].config3PLPresent && coordinatorSlotObject.fenceId && coordinatorSlotObject.fenceId != 0 ){
                            const selectedSlotFenceId = coordinatorSlotObject.fenceId;
                            dispatch(setState(CONSTANTS.SET_DYNAMIC_SLOTS_DATA, {res:coordinatorSlotObject.availableSlots,alertMessage:`*${containerConstants.formatString(containerConstants.SelectAnAvailableTimeSlot)}`, selectedSlotFenceId}));
                        } 
                        dispatch(setState(CONSTANTS.SET_DYNAMIC_SLOTS,{res:coordinatorSlotObject.availableSlots,alertMessage:`*${containerConstants.formatString(containerConstants.SelectAnAvailableTimeSlot)}`}));
                        return;
                    }
            }
            let alertMessage = "No Available Slot Found";
            let config3PLForSelectedForm = {};
            if(!isEmpty(config3PLDataMap) && config3PLDataMap[form.title]){
                 config3PLForSelectedForm = config3PLDataMap[form.title]; 
            }
            let res = await processUpdateFormService.getDynamicSlot(date,form,config3PLForSelectedForm,trackingDetails,processUrl);
           if(res){
              alertMessage =`*${containerConstants.formatString(containerConstants.SelectAnAvailableTimeSlot)}`
              const availableSlots = res.availableSlots
              const selectedSlotFenceId = res.fenceId
               dispatch(setState(CONSTANTS.SET_DYNAMIC_SLOTS_DATA,{availableSlots,selectedSlotFenceId, alertMessage}))
           } else {
              const availableSlots = []
              const selectedSlotFenceId = 0
              dispatch(setState(CONSTANTS.SET_DYNAMIC_SLOTS_DATA,{availableSlots,selectedSlotFenceId, alertMessage}))
           }
        } catch(err){
            console.log("err",err);
        }
    }
}

export function getAvailableDatesFromSlotBookingCoordinator(trackingDetails,form, config3PLDataMap,processUrl,dynamicSlotDates){
    return async dispatch => {
        try{
            let config3PLForSelectedForm = {}
          if(!isEmpty(config3PLDataMap) && config3PLDataMap[form.title]){
              config3PLForSelectedForm = config3PLDataMap[form.title]; 
         }
  
          dispatch(setState(CONSTANTS.SET_FORM_LOADING, true));
           
          let response = await processUpdateFormService.getAvailableDatesFromSlotBookingCoordinator(trackingDetails,form, config3PLForSelectedForm,processUrl,dynamicSlotDates);
  
           dispatch(setState(CONSTANTS.SET_FORM_LOADING, false));
  
           if(response){
               dispatch(setState(CONSTANTS.SET_AVAILABLE_DATES_FROM_COORDINATOR,response));
           }
        }
        catch(e){
            console.log(e);
        }
    }
  }

export function updateFormDataOnlyFormData(processInteractionKeysDtos,masterCode,referenceNumber, url,title,pudo,dynamicSlotDate,dynamicSlots, config3PLDataMap,trackingDetails,form,dynamicSlotList,processUrl,isPayment,productAmount,type, selectedSlotFenceId){
    return async function(dispatch){
        try{
            let config3PLForSelectedForm = {};
            if(!isEmpty(config3PLDataMap) && config3PLDataMap[title]){
                config3PLForSelectedForm = config3PLDataMap[title]; 
            }
            let res = await processUpdateFormService.updateProcessForm(dynamicSlotDate,dynamicSlots,config3PLForSelectedForm,trackingDetails,form,url,title,dynamicSlotList,processUrl,processInteractionKeysDtos,masterCode,referenceNumber,isPayment,productAmount,pudo,type, selectedSlotFenceId);
            return res;
         }
        catch(err){
            console.log("err",err);
            return "400";
        }
    }
}

export function updateFormData(processInteractionKeysDtos,masterCode,referenceNumber, url,title,pudo,dynamicSlotDate,dynamicSlots, config3PLDataMap,trackingDetails,form,dynamicSlotList,processUrl,isPayment,productAmount,type, selectedSlotFenceId,isReturn){
    return async function(dispatch){
        try{           
            let config3PLForSelectedForm = {};
            if(!isEmpty(config3PLDataMap) && config3PLDataMap[title]){
                config3PLForSelectedForm = config3PLDataMap[title]; 
           }
           
            let res = await processUpdateFormService.updateProcessForm(dynamicSlotDate,dynamicSlots,config3PLForSelectedForm,trackingDetails,form,url,title,dynamicSlotList,processUrl,processInteractionKeysDtos,masterCode,referenceNumber,isPayment,productAmount,pudo,type, selectedSlotFenceId, isReturn);
            if(type && type==CONSTANTS.CURBSIDE_PICKUP){
                if(res){
                    setTimeout(
                        function() {
                            dispatch(setCurbsidePickupData(url));
                        }.bind(this),
                        2000
                      );       
                }
                return res;
         }
            if(!config3PLForSelectedForm.config3PLPresent && res.initiatedTime!=undefined){
                let time = res.initiatedTime;
                let alertMessage ="";
                dispatch(setState(CONSTANTS.SET_INITIATED_TIME,{time,alertMessage}))
                
            }
            if(res=="200" || (config3PLForSelectedForm.config3PLPresent && res.status == 200)){
            let holidayMaster;
            const deviceType = await processUpdateFormService.getDeviceType()        // check device is mobile/tablet  or desktop
            let response =   await processUpdateFormService.getTrackingDetails(url,deviceType,isReturn);
            const processUpdateFormResponse =   await processUpdateFormService.getProcessUpdateForms(url,deviceType,isReturn);
            if(processUpdateFormResponse){
                response = {...processUpdateFormResponse,...response}
            }
            let config3PLDataMap = response.config3PLDataMap || {};
            let processUrl = response.processUrl;
            if(response=="feedbackUpdated"){
                dispatch(setState(CONSTANTS.SET_FEEDBACK,true))
            }
            else if(response=="notFound"){
                dispatch(setState(CONSTANTS.NOT_FOUND,true))
            } else {
                let check={},alreadyUpdated = false;
                if(typeof response == 'object'){response.isMobileType=deviceType;}
                if(response.updated){
                     alreadyUpdated = true;
                    dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster,config3PLDataMap,processUrl}))
                } else {
                    if(response.hubId){
                        holidayMaster = await processUpdateFormService.getHolidayMaster(url,response.hubId);
                    }
                    if(response.processInteractionKeysDtos){
                                response.processInteractionKeysDtos.map(attributes=>{
                                    if(attributes.typeId==8 && attributes.value && !isEmpty(attributes.value)){
                                        let value = JSON.parse(attributes.value)
                                        value.map(values=>{
                                            check[values] =true;
                                        })     
                                    }
                                }) 
                            }
                        dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster,config3PLDataMap,processUrl}))
                   }
            }
        }
            return res;
             
        } catch(err){
            console.log("err",err);
            return "400";
        }
    }
}

export function updatePaymentInfo(deleteParallelPayment,url){
    return async function(dispatch){
        try{
         if(deleteParallelPayment){
           let res  = await processUpdateFormService.cancelParallelPayments(url)
           if(res=="200"){
            let time = -1;
            let alertMessage ="Your previous transaction are cancelled"
            dispatch(setState(CONSTANTS.SET_INITIATED_TIME,{time,alertMessage}))
           }
           return res;
         } else {
            let time = -1;
            dispatch(setState(CONSTANTS.SET_INITIATED_TIME,time))
         }
        }catch(err){
            console.log("err",err);
        }
        
    }
}


export function getPaymentOrderDetails(url,orderId){
    return async function(dispatch){
        try {   
                let response = await processUpdateFormService.getPaymentOrderDetails(url,orderId);
                if(response && !isEmpty(response))  
                dispatch(setState(CONSTANTS.SET_PAYMENT_STATUS,response.status));
        } catch(err){
                console.log("err",err);    
        }
    }
}

export function fetchHolidayMaster(url, code) {
    return async function(dispatch){
        try{
            const resp = await processUpdateFormService.fetchHolidayMaster(url, code);
            if(resp && !isEmpty(resp))
            dispatch(setState(CONSTANTS.SET_HOLIDAY_MASTER, resp));
        } catch(err) {
            
        }
    }
}

export function getLastMileAndStatusDetail(url,trackingDetails, isReturn){
    return async function(dispatch){
        try{
            
              const res = await processUpdateFormService.getLastMileAndStatusDetail(url, isReturn,trackingDetails.orderLevelLayoutScreenDTO?true:false);
              trackingDetails["enableLayoutScreen"]=false;
              if(res && res != 'notFound'){
                let newSelection = { ...trackingDetails, ...res };
                var status = res["status"] ? res["status"] : "";
                var statusCategory = res["statusCategory"] ? res["statusCategory"] :"";
                const etaResponse = res.showEta?await processUpdateFormService.getEta(url, isReturn,status,statusCategory):{};
                if(etaResponse && etaResponse.layoutScreen && trackingDetails.layoutScreen){
                    newSelection.layoutScreen["eta"]=etaResponse.layoutScreen.eta;
                    newSelection.layoutScreen["etaSource"]=etaResponse.layoutScreen.etaSource;
                    newSelection.layoutScreen["sequenceEnabled"]=etaResponse.layoutScreen.sequenceEnabled;
                    newSelection.layoutScreen["trackingEnabled"]=etaResponse.layoutScreen.trackingEnabled;
                    newSelection.layoutScreen["etaForMap"]=etaResponse.layoutScreen.etaForMap;
                }
                if(trackingDetails && trackingDetails.disableMEta){
                    newSelection.disableMEta = true;
                }

                newSelection["newLayout"] = trackingDetails["newLayout"]; 

                dispatch(setState(CONSTANTS.UPDATE_TRACKING_DETAILS,newSelection))
              } else {
                 dispatch(setState(CONSTANTS.UPDATE_TRACKING_DETAILS,trackingDetails))
              }
             
        } catch(err){
            console.log("err",err);
        }
    }
}

export function fetchFeedbackInfo(url,isV2, type,isReturn){
    return async function(dispatch){
        try{
            const response = await processUpdateFormService.fetchFeedbackInfo(url, type,isReturn);
            if(isV2){
                if(response && response["feedbackUpdated"]){
                    if(response.customerInteractionThemeSettingsDTO) {
                        try{
                            response.customerInteractionThemeSettingsDTO = JSON.parse(response.customerInteractionThemeSettingsDTO);
                        } catch(err) {
                            console.log(err);
                            response.customerInteractionThemeSettingsDTO = null;
                        }
                    }
                    dispatch(setState(CONSTANTS.SET_FEEDBACK,{feedback:true,details:response}))
                }
            } else if(response) {
              dispatch(setState(CONSTANTS.SET_FEEDBACK_INFO,{isFeedbackInfoFetch:true,feedbackInfo:response}))
            }
        } catch(err){
            console.log("err",err);
        }
    }
}
export function storeCountsForClickedButton(event,url,merchantCode,isReturn){
    return async function(){
      let  labelAndButton =  await storeClickCountService.getClickedButtonAndLabel(event);
      let label = labelAndButton.label;
      let clickedButton = labelAndButton.clickedButton;
      if(!isEmpty(label) && !isEmpty(clickedButton) && !isEmpty(url)){
        storeClickCountService.storeClickedButtonCount(label,clickedButton,url,merchantCode,isReturn)
      }
    }
}
export function incrementAutoSavePufFlagCount(flagCount){
    return async dispatch => {
    dispatch(setState(CONSTANTS.INCREASE_AUTO_SAVE_FLAG_COUNT,flagCount))
    }
}
export function incrementHolidayMasterApiCount(flagCount){
    return async dispatch => {
    dispatch(setState(CONSTANTS.INCREASE_HOLIDAY_MASTER_API_COUNT,flagCount))
    }
}
/********* Method to set language Direction in local Storage so that we can use globally in styles.*****/
export function changeLanguageDirection(languageDirection) {
    return () => {
      window.sessionStorage.setItem('languageDirection', languageDirection);
    };
  }
  
