import React, { Component } from "react";
import "../LayoutScreen/style.css";
import OrderInformation from "../../components/OrderInformation";
import { bindActionCreators } from "redux";
import './../../../reactApp/style';

import { connect } from "react-redux";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";

import Grid from "@material-ui/core/Grid";
import FieldExecutiveDetail from "../../components/FieldExecutiveDetail";
import Banner from "../../components/Banner";
import Carousel from "../../components/Carousel";
import SocialMedia from "../../components/SocialMedia";
import OrderTracking from "../OrderTracking";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import * as LivetrackingAction from "../LiveTracking/LiveTrackingAction";
import EmbedTracking from "../LiveTracking/EmbedTracking";
import { isEmpty, cloneDeep } from "loadsh";
import MessageToFE from '../../components/MessageToFE'
import Snackbar from '@material-ui/core/Snackbar';
import ArrivingEta from "../../components/ArrivingEta";
import FeedbackV3 from "../FeedbackV3";
import ReturnInstruction from "../../components/ReturnInstruction";
import 'antd/dist/antd.css';
import { Carousel as Carousel1 } from 'antd';
// import { RightOutlined } from '@ant-design/icons'
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { isEqual } from "lodash";
import IframeGoogleDocs from "../../components/GoogleDocViewer";
import { mediaLinkService } from "../../services/MediaLinkService";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import CIPudoReturnMap from "../../containers/LayoutScreen/PudoReturnMap/CIPudoReturnMap";
// import CIPudoReturnMapList from "../../containers/LayoutScreen/PudoReturnMap/CIPudoReturnMapList";
import CIPudoReturnStoreDetails from "../../containers/LayoutScreen/PudoReturnMap/CIPudoReturnStoreDetails";
import MobileQuickAction from "../../components/QuickAction/MobileQuickAction";
import PudoMobileViewDetails from "../../components/PudoStoreDetails/pudoMobileViewDetails";
import { getPudoData } from "../MapView/MapViewAction"
import TableLoader from "../../components/TableLoader";
import { commonUtilities } from "../../services/commonUtilities";
import MobileViewOrder from "../MobileViewOrder";
import ShipmentList from "../../components/Shipment";
import OrderDetails from "../../components/Shipment/details";
import NewHelpSection from "../../components/NewHelpSection";
import StatusTimeLine from "../../components/StatusTimeLine";
import { listUtilities } from "../../services/ListUtilities";
import { ReturnToOrderIcon } from "../../components/HelpSection/icons";
import StoreClickCountLabelConstants from "../../Utils/StoreClickCountlabelConstants";
import { getBorderValue } from "../../Utils/themeConfig";


const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

class DeviceInt extends Component {
  messageToFE = "";
  monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  constructor(props) {
    super(props);
    this.state = {
      eventScreen: false,
      layoutScreen: false,
      message: false,
      marketingAssets: {},
      trackEnableTime: -1,
      isTripAPICalled: false,
      isEmbed: false,
    };
    this.setMessageModal = this.setMessageModal.bind(this);
    this.getRefereshData = this.getRefereshData.bind(this);
    this.trackingDetailsRefreshTimeout = null;
  }
  componentWillMount() {
    if (window.location.href.indexOf('&embed=true') != -1) {
      this.setState({
        isEmbed: true,
      })
    }
  }
  async componentDidMount() {
    document.body.style.overflowY = "auto";
    this.intervalId = setInterval(this.getRefereshData.bind(this), 5 * 60000);
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.jobId &&
      (!this.props.trackingDetails.layoutScreen || !this.props.trackingDetails.layoutScreen.eta)
    ) {
      var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
      await this.props.actions.getTripETA(
        this.props.url,
        this.props.trackingDetails.jobId,
        this.props.trackingDetails.min,
        this.props.trackingDetails.max,
        this.props.trackingDetails.liveTrackingEnableTime,
        this.props.trackingDetails.browserTimeZone,
        timeFormat,
        this.props.trackingDetails.orderId,
      );
    }
    this.intervalIdETa = setInterval(this.getTripETA.bind(this), 5 * 60000);
    if (!this.props.trackingDetails.calledFromDiy) {
      const marketingAssets = await processUpdateFormService.getMarketingAssets(this.props.url, null, this.props.isReturn);
      if (!isEmpty(marketingAssets)) {
        this.setState({ marketingAssets: marketingAssets });
      }
    }

    processUpdateFormService.checkGoogleAnalyticsCode(this.props.trackingDetails);
    this.checkPudoWithQuickAction();

    if (!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {
      this.previousJobsInfoIntervalId = setInterval(() => this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails), 60000);  // 60 sec

    }
    this.addDiyEventListner();
  }
  addDiyEventListner = () => {
    var marketingAsset = document.getElementById('marketingAsset');
    if (marketingAsset && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      marketingAsset.classList.add("borderHover");
      if (this.props.trackingDetails?.marketingAssets?.bannerDetails?.name == "demoBannerImageDiy") {
        marketingAsset.classList.add("diyFadeOut");
      }
      marketingAsset.insertBefore(buttonForEdit, marketingAsset.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("marketingAsset", "*");
      })
    }
    var needHelp = document.getElementById('helpButtonDiy');
    if (needHelp && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      needHelp.classList.add("borderHover");
      needHelp.insertBefore(buttonForEdit, needHelp.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("need-help", "*");
      })
    }
  }
  checkPudoWithQuickAction = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
      && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction
      && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true
      && isEmpty(this.props.pudoPoints)) {
      this.props.actions.getPudoData(
        this.props.url,
        this.props.trackingDetails.jobLat,
        this.props.trackingDetails.jobLng,
        this.props.trackingDetails.masterCode
      );
    }
  }

  async componentDidUpdate(nextProps) {
    if (this.props.preview) {
      if (!(this.props.trackingDetails?.marketingAssets?.bannerDetails?.name == "demoBannerImageDiy")) {
        var marketingAsset = document.getElementById('marketingAsset');
        marketingAsset.classList.remove("diyFadeOut");
      }
    }

    if (isEmpty(this.state.marketingAssets) && this.props.trackingDetails.marketingAssetDIY) {
      this.setState({ marketingAssets: this.props.trackingDetails.marketingAssetDIY });
    }
    if (this.props.trackingDetails && this.props.trackingDetails.nextIntervalTime &&
      nextProps.trackingDetails.nextIntervalTime != this.props.trackingDetails.nextIntervalTime &&
      nextProps.trackingDetails.nextIntervalTime < this.props.trackingDetails.nextIntervalTime + 2000) {
      if (this.trackingDetailsRefreshTimeout) {
        clearTimeout(this.trackingDetailsRefreshTimeout);
        this.trackingDetailsRefreshTimeout = null;
      }

      this.trackingDetailsRefreshTimeout = setTimeout(this.getRefereshData.bind(this), this.props.trackingDetails.nextIntervalTime + 100);
    }
    // this will set overflow auto as set in componentDidMount after help modal close in case
    if (nextProps.showNeedHelpDialog && nextProps.showNeedHelpDialog != this.props.showNeedHelpDialog) {
      setTimeout(function () {
        document.body.style.overflowY = "auto";
      }.bind(this), 300);
    }
    if (this.props.trackingDetails && this.props.trackingDetails.jobId && !this.state.isTripAPICalled) {
      var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
      if (this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {
        this.props.actions.getTripETA(this.props.url, this.props.trackingDetails.jobId, this.props.trackingDetails.min, this.props.trackingDetails.max, this.props.trackingDetails.liveTrackingEnableTime, this.props.trackingDetails.browserTimeZone, timeFormat, this.props.trackingDetails.orderId);
      }
      this.setState({ isTripAPICalled: true });
    }

    this.checkAndFetchFeedbackInfo(nextProps);

    if (!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled &&
      this.props.trackingDetails && nextProps.trackingDetails && this.props.trackingDetails.jobId != nextProps.trackingDetails.jobId) {
      await this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails);
    }

  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdETa);
    clearInterval(this.previousJobsInfoIntervalId);
    if (this.trackingDetailsRefreshTimeout) {
      clearTimeout(this.trackingDetailsRefreshTimeout);
      this.trackingDetailsRefreshTimeout = null;
    }
    this.removeNeedHelpEditElement();
  }
  removeNeedHelpEditElement = () => {
    var needHelp = document.getElementById('helpButtonDiy');
    if (needHelp) {
      needHelp.removeChild(needHelp.firstChild);
    }
  }
  hasQuickAction = () => {
    if ((this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList)) ||
      (this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints)) ||
      (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help) &&
        (!isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineContact) || !isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineEmail))
      )) {
      return true;
    }
    return false;
  }
  checkPudoScreen = () => {
    this.checkPudoWithQuickAction();
    this.props.openPudoScreen();
  }
  getQuickAction = () => {
    return (
      this.hasQuickAction() &&
      <div className="ci-pudo-detail-quick-action-div">
        <MobileQuickAction
          showNeedHelpDialog={this.state.showNeedHelpDialog}
          setHelpSection={this.closeHelpModal}
          trackingDetails={this.props.trackingDetails}
          openProcessForm={this.props.openQuickActionForm}
          screen={this.props.trackingDetails.screen}
          pudoPoints={this.props.pudoPoints}
          openPudoScreen={() => this.checkPudoScreen()}
          pudoTitle={
            this.props.trackingDetails &&
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.title
          }
          subLabel={
            this.props.trackingDetails &&
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.subLabel
          }
          fontFamily={this.props.fontFamily}
          fontSize={this.props.fontSize}
        />
      </div>
    );
  }
  checkAndFetchFeedbackInfo = (prevProps) => {
    if (
      isEmpty(prevProps) ||
      isEmpty(prevProps.trackingDetails) ||
      isEmpty(this.props.trackingDetails) ||
      isEmpty(this.props.trackingDetails.feedback) ||
      isEmpty(this.props.url)
    ) {
      return;
    }

    if (!isEqual(prevProps.trackingDetails.feedback, this.props.trackingDetails.feedback) && !this.props.isFeedbackInfoFetch) {
      if(this.props.isReturn){
        this.props.actions.fetchFeedbackInfo(this.props.url,false,"",this.props.isReturn);
      }
      else{
        this.props.actions.fetchFeedbackInfo(this.props.url);
      }
    }
  }

  getRefereshData() {

    // in case of live-tracking and fe movement no api call.
    if (this.props.url && this.props.trackingDetails && !(this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO
      && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]
      && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]) && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList
      && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList))) {
      this.props.actions.getTrackingDetails(this.props.url, this.props.isReturn, this.props.trackingDetails);
    }
  }

  async getTripETA() {
    if (this.props.trackingDetails
      && this.props.trackingDetails.jobId
      && this.props.trackingDetails.layoutScreen
      && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {

      var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
      this.props.actions.getTripETA(
        this.props.url,
        this.props.trackingDetails.jobId,
        this.props.trackingDetails.min,
        this.props.trackingDetails.max,
        this.props.trackingDetails.liveTrackingEnableTime,
        this.props.trackingDetails.browserTimeZone,
        timeFormat,
        this.props.trackingDetails.orderId,
      );
    }
  }



  openEventScreen = () => this.setState({ eventScreen: true });

  setMessageModal() {
    this.messageToFE = "";
    this.setState({
      message: !this.state.message,
      openSendMsgSnack: false
    });
  }

  handleSuccessSendMessage = () => {
    this.setState({
      message: false,
      openSendMsgSnack: true
    });
  }
  getSendMsgResponse = () => {
    return (
      <div className="send-msg-snack-bar">
        <Snackbar
          anchorOrigin={this.props.trackingDetails.isMobileType ?
            { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'right' }}
          open={this.state.openSendMsgSnack}
          message={containerConstants.InstructionsSentSuccessfully}
          key="sendMsgSnack"
          autoHideDuration={3500}
          onClose={() => { this.setState({ openSendMsgSnack: false }) }}
        />
      </div>

    );
  }

  renderBannerAndCorousel = () => {
    var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
    if (!isEmpty(marketingAssets)) {
      return (
        <div className="layout-scr-market-asset marketingAssetDiyMobile" id="marketingAsset">
          {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
            <Banner
              image={
                marketingAssets.bannerDetails.url
              }
              link={
                marketingAssets.bannerDetails.redirectUrl
              }
            />
          )}
          {marketingAssets.enableCarousel && !isEmpty(marketingAssets.carouselDetails) && (
            <div className="carousel-wrapper background-grey clearfix ">
              <Carousel
                secondaryTextColor={this.props.secondaryTextColor}
                carouselList={
                  marketingAssets && marketingAssets.carouselDetails
                }
              />
            </div>
          )}
        </div>
      );
    }
  };
  renderMarketingBanner = () => {
    var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
    if (!isEmpty(marketingAssets)) {
      return (
        <div className="layout-scr-market-asset marketingAssetDiyMobile" id="marketingAsset">
          {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
            <Banner
              image={
                marketingAssets.bannerDetails.url
              }
              link={
                marketingAssets.bannerDetails.redirectUrl
              }
            />
          )}
        </div>
      )
    }
  }

  generateReturnValidationUrl = (returnIntegration, merchantCode) => {
    if (isEmpty(returnIntegration)) {
      return "";
    }
    const returnUrl = new URL(returnIntegration.returnUrl);
    returnUrl.pathname = "validate";
    returnUrl.searchParams.append("companyCode", returnIntegration.companyCode);
    returnUrl.searchParams.append("firstValidation", returnIntegration.firstValidation);
    returnUrl.searchParams.append("secondValidation", returnIntegration.secondValidation);
    if (!isEmpty(merchantCode) && "null" != merchantCode) {
      returnUrl.searchParams.append("merchantCode", merchantCode);
    }
    return returnUrl.href;
  }

  renderInitiateReturn = () => {
    if (isEmpty(this.props.trackingDetails) ||
      isEmpty(this.props.trackingDetails.layoutScreen) ||
      isEmpty(this.props.trackingDetails.layoutScreen.returnIntegration) ||
      !this.props.trackingDetails.layoutScreen.returnIntegration.enabled
    ) {
      return null;
    }

    const returnIntegration = this.props.trackingDetails.layoutScreen.returnIntegration;
    const merchantCode = this.props.trackingDetails.vCode;
    return (
      <div className="layout-screen-return-cta">
        <a href={this.generateReturnValidationUrl(returnIntegration, merchantCode)}
          style={{
            backgroundColor: this.props.secondaryBgColor,
            color: this.props.secondaryTextColor,
          }}
          target="_blank">{containerConstants.formatString(containerConstants.ReturnOrder)}</a>
      </div>
    );
  }

  checkReturnInititation = () => {
    if (isEmpty(this.props.trackingDetails) ||
      isEmpty(this.props.trackingDetails.layoutScreen) ||
      isEmpty(this.props.trackingDetails.layoutScreen.returnIntegration) ||
      !this.props.trackingDetails.layoutScreen.returnIntegration.enabled
    ) {
      return false;
    }

    return true;
  }

  getShipmentData = (url) => {
    if (url) {
      const start = url.indexOf("tracking?") + "tracking?".length;
      const end = url.indexOf("#/");
      url = url.substring(start, end);
    }
    this.props.actions.getTrackingDetails(url, this.props.isReturn, this.props.trackingDetails, true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  showFestiveTheme = () => {
    return (
      <>
        <div className="ci-container-bg-img">
          <img src={this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
            && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin) &&
            this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin == "Winter" &&
            !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skinBackgroundImageUrl) ?
            this.props.trackingDetails.customerInteractionThemeSettingsDTO.skinBackgroundImageUrl : ''} />
        </div>
        {this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin) &&
          this.props.trackingDetails.customerInteractionThemeSettingsDTO.skin == "Winter"
          && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO.enableSnowfallEffect) && this.props.trackingDetails.customerInteractionThemeSettingsDTO.enableSnowfallEffect == "Yes" ?
          <div className="ci-v3-desktop-animation-">
            <Player
              autoplay
              loop
              src="https://assets6.lottiefiles.com/packages/lf20_by7epsey.json"
              style={{ height: '480px', width: '100%' }}
            >
              <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
          </div> : ''}
      </>
    )
  }


  checkReturnInfo = () => {
    const isReturnInitiationEnabled = this.checkReturnInititation();
    const ctaFontColor = this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaFontColor;
    const ctaColorWhite = this.props.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaColorWhite;
    return (
      <>
        {
          (this.props.isReturn && this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.instructionDetails && this.props.trackingDetails.screen != "feedbackScreen") &&
          <ReturnInstruction
            instructionDetails={this.props.trackingDetails.layoutScreen.instructionDetails}
            labelPDF={this.props.trackingDetails.layoutScreen.labelPDF}
            storeDetailsData={this.props.trackingDetails.layoutScreen.storeDetailsData}
            lastDate={this.props.trackingDetails.layoutScreen.lastDate}
            primaryBgColor={this.props.primaryBgColor}
            secondaryBgColor={this.props.secondaryBgColor}
            primaryTextColor={this.props.primaryTextColor}
            secondaryTextColor={this.props.secondaryTextColor}
          />
        }
      </>
    )
  }

  renderStatusHeader = () => {
    let card = this.props.newCiData?.eta?.card?.default ? this.props.newCiData?.defaultSettings?.card:this.props.newCiData?.eta?.card;
    let border = getBorderValue(card?.borderColour||'');
    return (
      <div className="statusHeader position-rtv" style={{ padding:'0',
        borderRadius:`${card?.cornerRedius||0}px`,
        backgroundColor:card?.cardBgcolour,
        borderTop:border[0],
        borderRight:border[1],
        borderBottom:border[2],
        borderLeft:border[3],
        }} >
        {this.showFestiveTheme()}
        <div style={{ zIndex: '2', position: 'relative',padding:'16px 20px'
        }}
        >
          {(this.props.trackingDetails.screen != "feedbackScreen" && isEmpty(this.props.trackingDetails.storeInfo) && !this.props.trackingDetails.disableMEta && window.location.href.indexOf('&embed=true') == -1) ?
            <ArrivingEta
              eta={this.props.updateEta}
              trackingDetails={this.props.trackingDetails}
              secondaryTextColor={this.props.secondaryTextColor}
              url={this.props.url}
              isJobRemoved={this.props.isJobRemoved}
              isReturn={this.props.isReturn}
              tripEta={this.props.tripEta}
              preview={this.props.preview}
              isMobileView={true}
              newCiData ={this.props.newCiData}
            />
            : null}
          {this.renderPudoStoreInfo()}
          <OrderInformation
            trackingDetails={this.props.trackingDetails}
            attributeList={this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.attributeList}
            secondaryTextColor={this.props.secondaryTextColor}
            eta={this.props.updateEta}
            url={this.props.url}
            isJobRemoved={this.props.isJobRemoved}
            isReturn={this.props.isReturn}
            tripEta={this.props.tripEta}
            newCiData ={this.props.newCiData}
          />
          {this.checkReturnInfo()}
        </div>
      </div>
    );
  };

  renderSocialMedia = () => {

    if (
      !isEmpty(this.props.trackingDetails) ||
      !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO) ||
      mediaLinkService.isFooterMediaLinkPresentInTheme(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
    ) {
      return (
        <SocialMedia
          theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
          layoutScreen={this.props.trackingDetails.layoutScreen}
          secondaryBgColor={this.props.secondaryBgColor}
          language={this.props.trackingDetails?.language || "en"}
          fontFamily={this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"}
          />
        );
      }

    if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen) {
      let layoutScreen = this.props.trackingDetails.layoutScreen;
      if (!layoutScreen.facebook && !layoutScreen.twitter && !layoutScreen.youtube && !layoutScreen.insta && !layoutScreen.tiktok && !layoutScreen.linkedIn && !layoutScreen.pinterest
        && !(layoutScreen.extraInfo && layoutScreen.extraInfo[0] && layoutScreen.extraInfo[0].label)
        && !(layoutScreen.extraInfo && layoutScreen.extraInfo[1] && layoutScreen.extraInfo[1].label)) {
        return;
      }
    }
    return (
      <SocialMedia
        theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
        layoutScreen={this.props.trackingDetails.layoutScreen}
        secondaryBgColor={this.props.secondaryBgColor}
        language={this.props.trackingDetails?.language || "en"}
        fontFamily={this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"}
      />
    );
  };
  timeToShowMap = () => {
    let timeToShowMap;
    if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaForMap && this.props.trackingDetails.liveTrackingEnableTime) {
      let trackingEnableETA = this.props.trackingDetails.layoutScreen.etaForMap - (this.props.trackingDetails.liveTrackingEnableTime * 60 * 1000)
      if (new Date().getTime() < trackingEnableETA) {
        let diffTime = Math.abs(trackingEnableETA - (new Date().getTime()));
        timeToShowMap = Math.ceil(diffTime / (1000 * 60))
      }
    }
    return timeToShowMap;
  }
  getPreviousJobDisplay = () => (
    <div className="prev-job-display-message">
      <span style={{ color: "white" }}>{this.props.previousJobsMessage}</span>
    </div>
  )
  renderLiveTrackingDetails = () => {
    if (this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.isJobToRemove) {
      return;
    }
    if ((this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.sequenceEnabled && !this.props.trackingDetails.layoutScreen.trackingEnabled) ||
      (this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta) || this.state.trackEnableTime != -1 || this.timeToShowMap()) {
      let time = this.state.trackEnableTime != -1 ? this.state.trackEnableTime : this.props.tripEta.enableEta;
      if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaSource == 'PROCESS_ATTRIBUTE' || this.props.trackingDetails.layoutScreen.etaSource == 'JOB_TRANSACTION') {
        time = this.timeToShowMap();
      }
      return (
        <div
          className="height10 flex p10"
          style={{ backgroundColor: "white", marginTop: "5px" }}
        >
          <div>
            <img src={require("../../images/tracking-mob.png")} />
          </div>
          <div className="ml10 pt10">
            <p className="fs14 mb5 fs-600" style={{ color: "#212121" }}>
              {time < 60 ?
                time < 10 ? containerConstants.formatString(containerConstants.LiveTrackingWillStart) + ' ' +
                  containerConstants.formatString(containerConstants.In) + ' ' + time + ' ' + containerConstants.formatString(containerConstants.Min) :
                  containerConstants.formatString(containerConstants.LiveTrackingWillStart) + ' ' +
                  containerConstants.formatString(containerConstants.In) + ' ' + time + ' ' + containerConstants.formatString(containerConstants.Mins)
                :
                containerConstants.formatString(containerConstants.TrackMovementInRealTime)
              }
            </p>
            <p className="fs12" style={{ color: "#727272" }}>
              {containerConstants.formatString(
                containerConstants.DriverTrackerWillGetActivatedOnceTheyAreOnWay
              )}
            </p>
          </div>
        </div>
      );
    } else if (this.props.trackingDetails && this.props.trackingDetails.userName) {
      return (<>
        {this.props.trackingDetails.trackingType=="iframe"?<div style={{height:"273px"}}><iframe  style ={{width:"100%", border:"none", height:"100%"}}src={this.props.trackingDetails.trackingMapUrl}></iframe></div>:<EmbedTracking trackingDetails={this.props.trackingDetails} newCiData={this.props.newCiData} />}
        {this.props.previousJobCount > 0 && this.state.isEmbed && this.getPreviousJobDisplay()}
      </>);
    }
  };

  renderFieldExecutiveDetails = () => {
    var showExecutiveBasedOnReEta = false;
    if (this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta && this.props.tripEta.enableEta > 0) {
      var showExecutiveBasedOnReEta = false;
    }
    else {
      showExecutiveBasedOnReEta = (this.timeToShowMap()) > 0 ? false : true;
    }

    if (this.props.trackingDetails && this.props.trackingDetails.showFE && showExecutiveBasedOnReEta) {
      return (
        <div className="bg-white">
          <FieldExecutiveDetail
            trackingDetails={this.props.trackingDetails}
            primaryBgColor={
              this.props.trackingDetails.customerInteractionThemeSettingsDTO
                .primaryBgColor
            }
            primaryTextColor={this.props.primaryTextColor}
            secondaryBgColor={this.props.secondaryBgColor}
            secondaryTextColor={this.props.secondaryTextColor}
            setMessageModal={this.setMessageModal}
            isMobileView={this.props.trackingDetails.isMobileType}
            isReturn={this.props.isReturn}
            preview={this.props.preview}
            newCiData ={this.props.newCiData}

          />
        </div>
      );
    }
    if (this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.isJobToRemove) {
      return;
    }
    return (
      <React.Fragment>
        {showExecutiveBasedOnReEta &&
          <div>
            <FieldExecutiveDetail
              trackingDetails={this.props.trackingDetails}
              primaryBgColor={this.props.trackingDetails.customerInteractionThemeSettingsDTO &&
                this.props.trackingDetails.customerInteractionThemeSettingsDTO
                  .primaryBgColor
              }
              primaryTextColor={this.props.primaryTextColor}
              secondaryBgColor={this.props.secondaryBgColor}
              secondaryTextColor={this.props.secondaryTextColor}
              setMessageModal={this.setMessageModal}
              isMobileView={this.props.trackingDetails.isMobileType}
              isReturn={this.props.isReturn}
              preview={this.props.preview}
              newCiData ={this.props.newCiData}

            />
          </div>}
      </React.Fragment>
    );
  };

  renderOrderTrackingScreen = () => {
    return (
      <OrderTracking
        track={false}
        back={true}
        subHeaderHide={true}
        backtoLayoutScreen={true}
        primaryBgColor={this.props.primaryBgColor}
        primaryTextColor={this.props.primaryTextColor}
        secondaryBgColor={this.props.secondaryBgColor}
        secondaryTextColor={this.props.secondaryTextColor}
        isReturn={this.props.isReturn}
        openProcessForm={this.props.openQuickActionForm}
        screen={this.props.trackingDetails.screen}
        pudoPoints={this.props.pudoPoints}
        openPudoScreen={this.props.openPudoScreen}
        pudoTitle={
          this.props.trackingDetails &&
          this.props.trackingDetails.pudo &&
          this.props.trackingDetails.pudo.title
        }
        subLabel={
          this.props.trackingDetails &&
          this.props.trackingDetails.pudo &&
          this.props.trackingDetails.pudo.subLabel
        }
        fontFamily={this.props.fontFamily}
        fontSize={this.props.fontSize}
        preview={this.props.preview}
        newCiData ={this.props.newCiData}

      />
    );
  };

  renderFEMessageDialoge = () => {
    return (
      <MessageToFE
        isMobileView={this.props.trackingDetails.isMobileType}
        feMsgDetails={{
          pageHeader: this.props.trackingDetails.feMsgPageHeader,
          supportingText: this.props.trackingDetails.feMsgSupportingText,
          preMessageArr: this.props.trackingDetails.preMessage
        }}
        sendMsgDetails={{
          url: this.props.url,
          orderId: this.props.trackingDetails.orderId,
          userId: this.props.trackingDetails.userId
        }}
        goBack={this.setMessageModal}
        handleSuccessSendMessage={this.handleSuccessSendMessage}
        openModal={this.state.message}
        fontType={this.props.newCiData?.defaultSettings?.typeface?.fontType}
      />
    );
  };

  renderTrackingInfo = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.userName)
      return (
        <div>
          {this.props.trackingDetails.screen != "feedbackScreen" &&
            <React.Fragment>
              {(this.props.trackingDetails && !this.props.trackingDetails.showFE) && window.location.href.indexOf('&embed=true') == -1 &&
                this.renderLiveTrackingDetails()}
              {this.renderFieldExecutiveDetails()}
            </React.Fragment>
          }
        </div>
      )
  }


  renderPudoStoreInfo = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.screen != "feedbackScreen"
      && this.props.trackingDetails.storeInfo && !isEmpty(this.props.trackingDetails.storeInfo)) {
      return (
        <div className="ci-pudo-order-details-desktop">
          <PudoMobileViewDetails
            trackingDetails={this.props.trackingDetails}
            eta={this.props.updateEta}
            url={this.props.url}
            isJobRemoved={this.props.isJobRemoved}
            isReturn={this.props.isReturn}
            tripEta={this.props.tripEta}
          />
        </div>
      )
    }
  }
  renderStatusTimeLine = () => {
    let theme =
      this.props.trackingDetails &&
        this.props.trackingDetails.customerInteractionThemeSettingsDTO
        ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        : undefined;
        let timeLine = this.props.newCiData?.timeLine;
        let defaultSettings = this.props.newCiData?.defaultSettings;
        let border = getBorderValue(this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.borderColour:this.props.newCiData?.timeLine?.card?.borderColour||'')
    return (
      <div className="status_log_screen"
      style={{
        backgroundColor:this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.timeLine?.card?.cardBgcolour,
        borderRadius:`${this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.timeLine?.card?.cornerRedius||10}px`,
        borderTop:border[0],borderRight:border[1],borderBottom:border[2],borderLeft:border[3],
       }}
      >
        <h3 style={{ borderBottom: "1px solid #dadada",
          fontWeight: timeLine?.headerText?.fontWeight,
          color: timeLine?.headerText?.fontColour,
          fontFamily:`${timeLine?.headerText?.fontType ? timeLine?.headerText?.fontType : this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
        }}>
        {timeLine?.headerText?.textValue || containerConstants.formatString(containerConstants.TrackerTimeline)}
        </h3>

        <StatusTimeLine
          trackingDetails={this.props.trackingDetails}
          primaryBgColor={theme && theme.primaryBgColor}
          primaryTextColor={theme && theme.primaryTextColor}
          secondaryBgColor={theme && theme.secondaryBgColor}
          secondaryTextColor={theme && theme.secondaryTextColor}
          timeLine ={timeLine}
          leftPosition='0px'
          defaultSettings ={defaultSettings}
        />
      </div>
    )
  }
  renderBasicOrderInfo = () => {
    return (this.renderStatusHeader());
  }
  renderOrderInfo = () => {
    let card = this.props.newCiData?.orderInfo?.card?.default ? this.props.newCiData?.defaultSettings?.card:this.props.newCiData?.orderInfo?.card;
    let border = getBorderValue(card?.borderColour||'');
    return (
      (this.props.trackingDetails.layoutScreen.orderInformationDTOList && listUtilities.checkOrderDto(this.props.trackingDetails.layoutScreen.orderInformationDTOList)) ||
      (this.props.trackingDetails.layoutScreen.orderAttributeList && listUtilities.showOrderInfo(this.props.trackingDetails.layoutScreen.orderAttributeList))) ?
      <div className="widget7" style={{
        padding: "14px 24px",
        backgroundColor:card?.cardBgcolour||'white',
        borderRadius:`${card?.cornerRedius || 0}px`,
        borderTop:border[0],borderRight:border[1],borderBottom:border[2],borderLeft:border[3],
      }}>
        <h3 className="mb5" style={{ 
          borderBottom: "1px solid #DADBDB" ,
          fontWeight:this.props.newCiData?.orderInfo?.headerText?.fontWeight,
          color:this.props.newCiData?.orderInfo?.headerText?.fontColour,
          fontFamily:`${this.props.newCiData?.orderInfo?.headerText?.fontType ? this.props.newCiData?.orderInfo?.headerText?.fontType : this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
         }}
        >
                {this.props.trackingDetails.layoutScreen.orderInfoTitle || this.props.newCiData?.orderInfo?.headerText?.textValue || containerConstants.formatString(containerConstants.OrderInformation)}
                </h3>
        <div className="width-100">
          <OrderInformation
            trackingDetails={this.props.trackingDetails}
            arrayList={
              this.props.trackingDetails.layoutScreen.orderInformationDTOList
            }
            orderAttributeList={
              this.props.trackingDetails.layoutScreen.orderAttributeList
            }
            secondaryBgColor={this.props.secondaryBgColor}
            newCiData ={this.props.newCiData}

          />
        </div>
      </div>
      : ''
  }

  renderNeedHelp = () => {
    return (
      <NewHelpSection
        trackingDetails={this.props.trackingDetails}
        openNeedHelpProcessForm={this.props.openNeedHelpProcessForm} // will need this to open the PUf otherwise it will  break
        newCiData ={this.props.newCiData}

      />)
  }

  doHaveShipments(){
        if(this.props.trackingDetails?.orderLevelLayoutScreenDTO?.shipments && this.props.trackingDetails?.orderLevelLayoutScreenDTO?.shipments.length>1){
            return true;
        }
        return false;
  }


  renderShipmentList = () => {
    let card = this.props.newCiData?.multiShipment?.card?.default ? this.props.newCiData?.defaultSettings?.card:this.props.newCiData?.multiShipment?.card;
    let border = getBorderValue(card?.borderColour||'');
    return (
      <div style={{marginTop:'13px'}}>
        <div className="bg-white" style={{backgroundColor:card?.cardBgcolour||'white',borderRadius:card?.cornerRedius?`${card?.cornerRedius||10}px ${card?.cornerRedius||10}px 0px 0px`:'',borderTop:border[0],borderRight:border[1],borderLeft:border[3],display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 24px", fontWeight: "700", fontSize: "16px", borderBottom: "1px solid #E8DFD5" }}>
          <p style={{ margin: 0 }}>{containerConstants.formatString(containerConstants.OtherShipments)}</p>
          <div id={StoreClickCountLabelConstants.RETURN_TO_ORDER_TRACKING} onClick={() => { this.getShipmentData(window.location.href) }} style={{ cursor: "pointer" }}>
            <ReturnToOrderIcon />
          </div>
        </div>
      <ShipmentList
        containerConstants={containerConstants}
        referenceNumber={this.props.trackingDetails.referenceNumber}
        orderData={this.props.trackingDetails.orderLevelLayoutScreenDTO}
        getShipmentData={this.getShipmentData}
        theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
        isNewLayout={this.props.trackingDetails.newLayout}
        card={card}
        border={border}
      />
      </div>
    )
  }
  renderRating = () => {
    return (<div className="feedbackScreenNew feedback_page feedbackDiyMobile" id="feedbackDiy"style={{fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType}}>
     
        <FeedbackV3
          primaryBgColor={this.props.primaryBgColor}
          primaryTextColor={this.props.primaryTextColor}
          closeRattingScreenDrawer={this.closeRattingScreenDrawer}
          fontFamily={this.props.fontFamily}
          fontSize={this.props.fontSize}
          secondaryBgColor={this.props.secondaryBgColor}
          secondaryTextColor={this.props.secondaryTextColor}
          feedbackInfo={this.props.feedbackInfo}
          preview={this.props.preview}
          newCiData ={this.props.newCiData}
          isReturn={this.props.isReturn}
        />
    </div>);
  }
  getHeight = ()=>{
    if(this.props.universalTracking){
      let universalTracking=this.props.newCiData?.universalSearch?.universalTracking;
      if(universalTracking?.hideFooter){
        if(universalTracking?.hideHeader){
          return "100vh";
        }
        else{
          return "calc(100vh - 55px)";
        }
      }
      else if(universalTracking?.hideHeader){
          return "calc(100vh - 111px)";
      }
    }
    return "";
  }
  showFooter = ()=>{
    if(this.props.universalTracking && this.props.newCiData?.universalSearch?.universalTracking?.hideFooter){
      return false;
    }
    return true;
  }
  renderLayoutScreen = () => {
    let newLayout = this.props.trackingDetails?.newLayout?"layout-screen-mobile-wrapper miller-knoll":"layout-screen-mobile-wrapper";
    let pod = this.props.newCiData?.pod;
    return (
      <React.Fragment>
        <div className={newLayout} style={{minHeight: this.getHeight(),fontFamily:`${this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`}}>
          <div className="widget1 feedback_page">
            {this.renderPodImages()}
          </div>
          <hr className="seprator" />
          <div className="widget2">{this.renderBasicOrderInfo()}
          </div>
          {this.props.trackingDetails.screen == "feedbackScreen" && <div className="widget3">{this.renderRating()}</div>}
          <div className="widget4">{this.renderTrackingInfo()}
          </div>
          <hr className="seprator" />
          <div className="widget5" style={{padding: "14px 24px"}}>{this.renderStatusTimeLine()}</div>

          {/* <hr className="seprator" /> */}

          <div className="widget6">{this.renderMarketingBanner()}</div>

          {/* <hr className="seprator" /> */}

          {this.renderOrderInfo()}

          {/* <hr className="seprator" /> */}

          {
            this.doHaveShipments() && <>{this.renderShipmentList()}</>
          }
          <div className="widget8">{this.hasQuickAction() && this.renderNeedHelp()}</div>

        </div>
        <div>
          {this.showFooter() && this.renderSocialMedia()}
        </div>
      </React.Fragment>
    );
  };

  ArrayPod = (feedback) => {
    let podImageCss=this.state.feedPopup?"":"pod-img";
    let arr = [];
    if (feedback && feedback.podImageArrayList) {
      {
        for (let i = 0; i < feedback.podImageArrayList.length; i++) {
          if (!isEmpty(feedback.podImageArrayList[i]) && feedback.podImageArrayList[i].match(/\.pdf$/)) {
            arr.push(<div className="box relative" >
              <div className="layer"></div>
              <div onClick={this.togglePopup.bind(this)}>
                {feedback.podImageArrayList[i].indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImageArrayList[i] + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImageArrayList[i]}
                  />
                }
                <div className="feedback-iframe-layer"></div>
              </div>
            </div>)
          } else if (this.checkIfPodImageExist(feedback.podImageArrayList[i])) {
            arr.push(<div className={"box"+ podImageCss}>
              <img src={feedback.podImageArrayList[i]} onClick={this.togglePopup.bind(this)} className="" />
            </div>)
          }
        }
      }
      return arr;
    }
    else {
      return arr;
    }
  }

  renderCarousel = (feedback, status) => {
    if (this.ArrayPod(feedback).length >= 1) {
      return (
        <div className="feedback_carousel">
          <Carousel1
            arrows nextArrow={<span className="right_arrow"><FiChevronRight /></span>}
            prevArrow={<span className="left_arrow"><FiChevronLeft /></span>}
          >
            {this.ArrayPod(feedback)}
          </Carousel1>
        </div>
      )
    }
  }

  renderPodImages = () => {
    if (!this.props.trackingDetails.feedback) {
      return;
    }
    let feedback = JSON.parse(this.props.trackingDetails.feedback);
    let pod = this.props.newCiData?.pod;
    this.checkIfPodImageExist(feedback.podImage) &&  <div style={{
                fontWeight:pod?.headerText?.fontWeight,
                color : pod?.headerText?.fontColour,
                fontFamily:`${pod?.headerText?.fontType ? pod?.headerText?.fontType : this.props.newCiData?.defaultSettings?.typeface?.fontType||"Roboto"},sans-serif`,
                backgroundColor: pod?.headerContainer?.backgroundColour
              }}>
                    {pod?.headerText?.textValue}
            </div>
    if (this.checkIfPodImageExist(feedback.podImage) && this.ArrayPod(feedback).length == 0) {
      if (feedback.podImage.match(/\.pdf$/)) {
        return (
          this.state.feedPopup ?
            <div className='feed_popup'>
              <div className="podsinglepop">
                {feedback.podImage.indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImage + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImage}
                  />
                }

                <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
              </div>
            </div>
            :
            <div className="box relative">
              <div className="layer"></div>
              <div onClick={this.togglePopup.bind(this)}>
                {feedback.podImage.indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImage + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={feedback.podImage}
                  />
                }
                <div className="feedback-iframe-layer"></div>
              </div>
            </div>

        );
      }
      return (
        this.state.feedPopup ?
          <div className='feed_popup'>
            <div className="podsinglepop box">
              <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
              <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
            </div>
          </div>
          :
          <div className="relative">
            <div className="layer"></div>
            <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
          </div>
      );
    }
    else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList) && feedback.podImageArrayList.length == 1 && this.checkIfPodImageExist(feedback.podImageArrayList[0])) {
      if (feedback.podImageArrayList[0].match(/\.pdf$/)) {
        return (
          this.state.feedPopup ?
            <div className='feed_popup'>
              <div className="podsinglepop">
                {feedback.podImageArrayList[0].indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImageArrayList[0] + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={eedback.podImageArrayList[0]}
                  />
                }

                <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
              </div>
            </div>
            :
            <div className="box relative">
              <div className="layer"></div>
              <div onClick={this.togglePopup.bind(this)}>
                {feedback.podImageArrayList[0].indexOf("https://") == 0 ?
                  <iframe
                    className="feedback-iframe"
                    src={feedback.podImageArrayList[0] + "#toolbar=0"}
                  /> :
                  <IframeGoogleDocs
                    url={eedback.podImageArrayList[0]}
                  />}

                <div className="feedback-iframe-layer"></div>
              </div>
            </div>

        );
      }
      return (
        this.state.feedPopup ?
          <div className='feed_popup'>
            <div className="podsinglepop box">
              <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
              <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
            </div>
          </div>
          :
          <div className="relative">
            <div className="layer"></div>
            <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
          </div>
      );
    }
    else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList)) {
      return (
        this.state.feedPopup ?
          <div className='feed_popup'>
            {this.renderCarousel(feedback, true)}
            <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
          </div>
          :
          this.renderCarousel(feedback, false)
      );
    }
  }

  closeRattingScreenDrawer = () => {
    setTimeout(function () {
      document.body.style.overflowY = "auto";
    }.bind(this), 300);
  }

  checkIfPodImageExist = (img) => {
    return ((!isEmpty(img))) && ((img.indexOf("http://") == 0 || img.indexOf("https://") == 0));
  }

  checkFeedbackScreen = () => {
    if (!this.props.trackingDetails.feedback) {
      return;
    }

    let feedback = JSON.parse(this.props.trackingDetails.feedback);
    let marginTopVal = "0px"
    if (!((this.checkIfPodImageExist(feedback.podImage)) || this.ArrayPod(feedback).length >= 1)) {
      marginTopVal = "100px"
    }

    if (this.props.trackingDetails.screen == "feedbackScreen") {
      return (
        <div className="feedback_page" style={{ marginTop: marginTopVal }}>

          {this.renderPodImages(feedback)}
          <div className="feedbackScreenNew feedbackDiyMobile" id="feedbackDiy" >
            {this.props.trackingDetails.screen == "feedbackScreen" &&
              <FeedbackV3
                primaryBgColor={this.props.primaryBgColor}
                primaryTextColor={this.props.primaryTextColor}
                closeRattingScreenDrawer={this.closeRattingScreenDrawer}
                fontFamily={this.props.fontFamily}
                fontSize={this.props.fontSize}
                secondaryBgColor={this.props.secondaryBgColor}
                secondaryTextColor={this.props.secondaryTextColor}
                feedbackInfo={this.props.feedbackInfo}
                preview={this.props.preview}
                newCiData ={this.props.newCiData}
                isReturn={this.props.isReturn}
              />}
          </div>
        </div>
      )
    }
  }
  checkLandingPage = () => {
    if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
      let processUpdateForm = JSON.parse(this.props.trackingDetails.processUpdateForm);
      if (processUpdateForm.formList) {
        const formList = processUpdateForm.formList;
        for (let i = 0; i < formList.length; i++) {
          if (formList[i].landingPage && formList[i].landingPage == true) {
            return (

              this.props.openProcessForm(i)
            );
          }
        }
      }
    }
    return this.renderLayoutScreen();

  }

  getScreen = () => {
    if (this.state.eventScreen) {
      return this.renderOrderTrackingScreen();
    } else if (this.state.message) {
      return this.renderFEMessageDialoge();
    } else {
      return this.checkLandingPage();
    }
  }
  togglePopup() {
    // alert("Hello")
    this.setState({
      feedPopup: !this.state.feedPopup
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="layout-screen-customize" style={{backgroundColor: this.props.newCiData?.defaultSettings?.colourSystem?.bg_colour || "#F3F3F3"}}>
          {this.getSendMsgResponse()}
          {this.getScreen()}
        </div>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...CustomerInteractionAction, ...LivetrackingAction, getPudoData },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
    tripEta: state.customerInteractionReducer.tripEta,
    updateEta: state.customerInteractionReducer.updateEta,
    isJobRemoved: state.customerInteractionReducer.isJobRemoved,
    isFeedbackInfoFetch: state.customerInteractionReducer.isFeedbackInfoFetch,
    feedbackInfo: state.customerInteractionReducer.feedbackInfo,
    pudoPoints: state.pudoReducer.pudoPoints,
    previousJobCount: state.customerInteractionReducer.previousJobCount,
    previousJobsMessage: state.customerInteractionReducer.previousJobsMessage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInt);